import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Trc from './TRC.png';
import './navbar8.css';
import 'font-awesome/css/font-awesome.min.css';
import { FaFacebook, FaInstagram } from 'react-icons/fa'; 
import phoneIcon from './phone.png';
import CellphoneIcon from './cell-phone.png'


const Navbar8 = (props) => {
  const [link5AccordionOpen, setLink5AccordionOpen] = useState(false);
  const [link5DropdownVisible, setLink5DropdownVisible] = useState(false);


 
  return (
    <header className="navbar8-container1">
      {/* Full-width banner at the top */}
      <div className="navbar8-banner">
  <div 
    className="navbar8-sliding-text" 
    style={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: '10px',  // Space between items
      whiteSpace: 'nowrap', // Ensure items stay in a single line
      animation: 'scrollBanner 15s linear infinite'  // Animation for scrolling
    }}
  >
    <span>TRC Catering -Ste Tunisienne de Résidence et Catering</span>
    <span style={{ margin: '0 5px' }}>|</span>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <a 
        href="tel:+21699084084" 
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          textDecoration: 'none', 
          color: 'inherit' 
        }}
      >
        <img 
          src={CellphoneIcon} 
          alt="Phone Icon" 
          style={{ 
            width: '15px', 
            height: '15px', 
            marginRight: '5px', 
            filter: 'invert(1) brightness(2)' 
          }} 
        />
        Tel: 98 750 850
      </a>
      <a 
        href="tel:+21698750850" 
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          textDecoration: 'none', 
          color: 'inherit' 
        }}
      >
        &nbsp;&nbsp;
        <img 
          src={phoneIcon} 
          alt="Phone Icon" 
          style={{ 
            width: '15px', 
            height: '15px', 
            marginRight: '5px', 
            filter: 'invert(1) brightness(2)' 
          }} 
        />
        fix : 71 601 401
      </a>
      <span style={{ margin: '0 5px' }}>|</span>
      <a href="mailto:contact@trc-catering.com" style={{ color: 'inherit', textDecoration: 'none' }}>
        ✉ Email: contact@trc-catering.com
      </a>
    </div>
  </div>
</div>



      <header data-thq="thq-navbar" className="navbar8-navbar-interactive">
      <Link to="/">
  <img
    alt={props.logoAlt}
    src={Trc}
    className="navbar8-image1"
    style={{ width: '140px', height: 'auto' }}
  />
</Link>
        <div data-thq="thq-navbar-nav" className="navbar8-desktop-menu">
          <nav className="navbar8-links1">
            <a href={props.link1Url} className="navbar8-link11 thq-link thq-body-small">
              {props.link1 ?? (
                <Fragment>
                  <span className="navbar8-text18">index</span>
                </Fragment>
              )}
            </a>
          
           
          </nav>

          {/* Buttons section with the Facebook button placed to the right of "Explorer nos services" */}
          <div className="navbar8-buttons1">
          
            <button className="navbar8-action21 thq-button-outline thq-button-animated">
              <span>
                {props.action2 ?? (
                  <Fragment>
                    <Link to="/Service" className="navbar8-text27">
                      Explorer nos services
                    </Link>
                  </Fragment>
                )}
              </span>
            </button> 
         
</div>

<div className="social-icons">
        <a href="https://www.facebook.com/trcatering" target="_blank" rel="noopener noreferrer">
          <FaFacebook size={30} style={{ marginLeft: '20px', marginTop: '30px' }} />
        </a>
       
      </div>





</div>



      
      </header>

      {/* Chatbot div */}
      <div id="docsbotai-root"></div>

      {link5DropdownVisible && (
        <div onClick={() => setLink5DropdownVisible(false)} className="navbar8-container8"></div>
      )}
    </header>
  );
};

export default Navbar8;
