// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
// Import other Firebase features if you need them (e.g., Authentication, Storage, etc.)

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCWhACYxQmck2pdXh8HMomfY72i7Hkmonw",
  authDomain: "atlasfoyer-d90b9.firebaseapp.com",
  projectId: "atlasfoyer-d90b9",
  storageBucket: "atlasfoyer-d90b9.firebasestorage.app",
  messagingSenderId: "666550191114",
  appId: "1:666550191114:web:00d46f970328c36e468bf3",
  measurementId: "G-4E6KX9MX0D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize Firestore

export { db, addDoc, collection }; // Export for use in components
