import React, { useState, Fragment } from 'react'
import ComfortImage from './Comfort.jpg'; // Assuming the image is in the same directory
import SecImage from './Sec.jpg';
import PlaceImage from './place.jpg'; 
import PropTypes from 'prop-types'

import './features25.css'

const Features25 = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className="thq-section-padding">
     
    </div>
  )
}

Features25.defaultProps = {
  feature3Description: undefined,
  feature1ImgAlt: 'Chambres confortables',
  feature1Description: undefined,
  feature3ImgAlt: 'Sécurité renforcée',
  feature2Title: undefined,
  feature1Title: undefined,
  feature1ImgSrc:
  ComfortImage,  feature3ImgSrc:
  SecImage,  feature2Description: undefined,
  feature3Title: undefined,
  feature2ImgAlt: 'Emplacement idéal',
  feature2ImgSrc:
   PlaceImage,}

Features25.propTypes = {
  feature3Description: PropTypes.element,
  feature1ImgAlt: PropTypes.string,
  feature1Description: PropTypes.element,
  feature3ImgAlt: PropTypes.string,
  feature2Title: PropTypes.element,
  feature1Title: PropTypes.element,
  feature1ImgSrc: PropTypes.string,
  feature3ImgSrc: PropTypes.string,
  feature2Description: PropTypes.element,
  feature3Title: PropTypes.element,
  feature2ImgAlt: PropTypes.string,
  feature2ImgSrc: PropTypes.string,
}

export default Features25
