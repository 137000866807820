import React from 'react';
import PropTypes from 'prop-types';
import Trc from './TRC.png'; // Logo Image
import phone from './phone.png'; // Phone Image
import Cellphone from './cell-phone.png'; // Phone Image
import facebook from './fb.png'; // Facebook Image
import instagram from './instagram.png'; // Facebook Image
import './footer4.css';

const Footer4 = (props) => {
  return (
    <footer className="footer4-footer7">
      <div className="footer4-content">
        {/* Contact Us and Follow Us Section */}
        <div className="footer4-sections">
          {/* Contact Us Section (Left) */}
          <div className="footer4-contact">
            <h4>Contact Us</h4>
            <div className="footer4-title-bar"></div>
            <div className="footer4-contact-item">
  <img src={Cellphone} alt="Phone" className="footer4-icon" />
  <a href="tel:+21698750850" className="footer4-phone-link">
    <span>98 750 850</span>
  </a>
</div>

            <div className="footer4-contact-item">
              <img src={phone} alt="Phone" className="footer4-icon" />
              <a href="tel:+21671601401" className="footer4-phone-link">
              <span>71 601 401</span>
              </a>
            </div>
          </div>

          {/* Vertical Divider */}
          <div className="footer4-divider"></div>

          {/* Foyer Logo in the Center */}
          <div className="footer4-logo-center">
            <img
              alt={props.logoAlt}
              src={Trc}
              className="footer4-logo-image"
            />
          </div>

          {/* Follow Us Section (Right) */}
          <div className="footer4-follow-us">
            <h4>Follow Us</h4>
            <div className="footer4-title-bar"></div>
            <div className="footer4-social-item">
              <img src={facebook} alt="Facebook" className="footer4-icon" />
              <a href="https://www.facebook.com/trcatering" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
            </div>
         
          
          </div>
        </div>
      </div>

      {/* Large Horizontal Line at the Bottom of Footer */}
      <div className="footer4-large-line"></div>

      {/* Footer Credits */}
      <div className="footer4-credits">
        <span>© 2024 Foudhaili Holding</span>
      </div>
    </footer>
  );
};

Footer4.defaultProps = {
  logoAlt: 'Atlas Foyer Logo',
};

Footer4.propTypes = {
  logoAlt: PropTypes.string,
};

export default Footer4;
