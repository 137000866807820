import React, { Fragment } from 'react'
import relaximg from './relax.jpg'
import PropTypes from 'prop-types'

import './contact10.css'

const Contact10 = (props) => {
  return (
    <div className="contact10-container1 thq-section-padding">
      <div className="contact10-max-width thq-section-max-width">
        <div className="contact10-content1 thq-flex-row">
          <div className="contact10-content2">
            <h2 className="thq-heading-2">
              {props.heading1 ?? (
                <Fragment>
                  <span className="contact10-text18">Contactez-nous</span>
                </Fragment>
              )}
            </h2>
            <p className="thq-body-large">
              {props.content1 ?? (
                <Fragment>
                  <span className="contact10-text16">
                    Nous sommes à votre disposition pour toute demande de service ou réservation, que ce soit pour l'hébergement ou le catering sur vos sites pétroliers.
                  </span>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <div className="contact10-content3 thq-flex-row">
          <div className="contact10-container2">
            <img
              alt={props.location1ImageAlt}
              src={props.location1ImageSrc}
              className="contact10-image1 thq-img-ratio-16-9"
            />
            <h3 className="contact10-text12 thq-heading-3">
              {props.location1 ?? (
                <Fragment>
                  <span className="contact10-text20">Services pour Travailleurs Pétroliers</span>
                </Fragment>
              )}
            </h3>
            <p className="thq-body-large">
              {props.location1Description ?? (
                <Fragment>
                  <span className="contact10-text17">
                    Nous offrons des résidences adaptées et un service de catering haut de gamme, spécialement conçus pour répondre aux exigences des travailleurs dans les environnements pétroliers.
                  </span>
                </Fragment>
              )}
            </p>
            <div className="contact10-container3">
              {/* Additional content can go here */}
            </div>
          </div>
          <div className="contact10-container4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.87676087767!2d10.074685575688601!3d36.82147297224103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd2daa2fa9c1d5%3A0xd86f14fe096af357!2sATLAS%20FOYER!5e0!3m2!1sen!2stn!4v1731062681944!5m2!1sen!2stn"
              width="600"
              height="375"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
            <h3 className="contact10-text14 thq-heading-3">
              {props.location2 ?? (
                <Fragment>
                  <span className="contact10-text21">Emplacement de nos Services</span>
                </Fragment>
              )}
            </h3>
            <p className="thq-body-large">
              {props.location2Description ?? (
                <Fragment>
                  <span className="contact10-text19">
                    Nous sommes stratégiquement situés à proximité des sites d'opérations pétrolières, offrant un accès facile à nos services de résidence et de restauration.
                  </span>
                </Fragment>
              )}
            </p>
            <div className="contact10-container5">
              <a
                href="https://maps.app.goo.gl/EkavJhooiW64BMWz7"
                target="_blank"
                rel="noreferrer noopener"
                className="thq-button-flat thq-body-small"
              >
                Directions
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Contact10.defaultProps = {
  content1: undefined,
  location2ImageSrc:
    'https://images.unsplash.com/photo-1669312716490-5766f391d69b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMTA3MTQ1M3w&ixlib=rb-4.0.3&q=80&w=1080',
  location1ImageSrc:
    relaximg,
  location1Description: undefined,
  location2ImageAlt: "Image de la carte montrant l'emplacement",
  heading1: undefined,
  location2Description: undefined,
  location1ImageAlt: "Image de l'extérieur du bâtiment",
  location1: undefined,
  location2: undefined,
}

Contact10.propTypes = {
  content1: PropTypes.element,
  location2ImageSrc: PropTypes.string,
  location1ImageSrc: PropTypes.string,
  location1Description: PropTypes.element,
  location2ImageAlt: PropTypes.string,
  heading1: PropTypes.element,
  location2Description: PropTypes.element,
  location1ImageAlt: PropTypes.string,
  location1: PropTypes.element,
  location2: PropTypes.element,
}

export default Contact10
