import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Navbar8 from '../components/navbar8';
import Footer4 from '../components/footer4';
import '../views/home.css';
import './Service.css';

// Ensure you have images in the proper directory
import icone1 from './icone1.png';
import icone2 from './icone2.png';

const Service = () => {
  const services = [
   
    {
      icon: icone2,
      title: "Catering",
      description: `Notre service de catering fournit des repas nutritifs et équilibrés, adaptés aux exigences spécifiques 
      de vos équipes dans des environnements de travail isolés. Nous proposons des menus diversifiés, 
      préparés avec des produits frais, pour assurer une alimentation saine et satisfaisante tout au long de la journée.`
    },
    {
      icon: icone1,
      title: "Residence",
      description: `La Ste Tunisienne de Résidence et Catering offre des solutions 
      d'hébergement de haute qualité adaptées aux besoins des entreprises et des travailleurs dans le secteur pétrolier. 
      Nous mettons à votre disposition des résidences confortables et sécurisées, 
      avec des services modernes pour garantir le bien-être de vos équipes, quel que soit le lieu ou les conditions.`
    },
  ];

  return (
    <div className="service-container">
      <Helmet>
        <title>TRC-Service</title>
        <meta property="og:title" content="Nos Services - Atlas Foyer" />
      </Helmet>

      {/* Navbar */}
      <Navbar8
        page4Description={<span className="home-text100">Contactez-nous pour toute question ou réservation.</span>}
        link3={<Link to="/Réserver" className="navbar8-text16">Réserver Maintenant</Link>}
        link2={<Link to="/" className="navbar8-text16">Accueil</Link>}
        page1={<span className="home-text103">Accueil</span>}
        link1={<Fragment></Fragment>}
        page4={<span className="home-text105">Contact</span>}
        page2={<Link to="/" className="navbar8-text16">Accueil</Link>}
      />

      {/* Service Intro */}
      <section className="service-intro">
        <div className="container">
          <div className="text-content">
            <h1>Nos Services</h1>
            <p>
              Bienvenue à TRC.
            </p>
            <p>
              Nous offrons des services exceptionnels pour vous assurer un confort optimal durant vos travaux.
            </p>
          </div>
        </div>
      </section>

      {/* Services List */}
      <section className="services-list">
        <div className="container">
          <ul>
            {services.map((service, index) => (
              <li key={index} className="service-item">
                <img
                  src={service.icon}
                  alt={`Icon for ${service.title}`}
                  className="service-icon"
                />
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Footer */}
      <Footer4 />
    </div>
  );
};

export default Service;
