import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Hero17 from '../components/hero17'
import Features24 from '../components/features24'
import CTA26 from '../components/cta26'
import Features25 from '../components/features25'
import Pricing14 from '../components/pricing14'
import Steps2 from '../components/steps2'
import Testimonial17 from '../components/testimonial17'
import Contact10 from '../components/contact10'
import Footer4 from '../components/footer4'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>TRC  Catering</title>
        <meta property="og:title" content="Gifted Eminent Elk" />
      </Helmet>
      <Navbar8
        page4Description={
          <Fragment>
            <span className="home-text100">
              Contactez-nous pour toute question ou réservation.
            </span>
          </Fragment>
        }
      
        link2={
          <Fragment>
            
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="home-text103">Accueil</span>
          </Fragment>
        }
        link1={
          <Fragment>
       
          </Fragment>
        }
        page4={
          <Fragment>
         <Link to="/Contacter" className="navbar8-text16">Contacter</Link>  {/* Link to home page */}
          </Fragment>
        }
        
      ></Navbar8>
      <Hero17
        action2={
          <Fragment>
          <Link to="/Contacter" className="navbar8-text16">Contacter</Link>  {/* Link to home page */}
          </Fragment>
        }
        action1={
          <Fragment>
          <Link to="/Service" className="navbar8-text16">Nos Services</Link>  {/* Link to home page */}
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text116">
            TRC 
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text117">
            Ste Tunisienne de Résidence et Catering est l’un des principaux fournisseurs de services pétroliers dans le domaine de résidence et catering    </span>
          </Fragment>
        }
      ></Hero17>
      <Features24
    
    
        feature2Description={
          <Fragment>
            <span className="home-text120">des repas préparés pour soutenir la performance et la santé.</span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="home-text121">Hébergement Confortable et Sécurisé</span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text122">
            Offrez à vos équipes des conditions de vie optimales
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text123">Repas Sains et Équilibrés</span>
          </Fragment>
        }
      ></Features24>
      <CTA26
        heading1={
          <Fragment>
            <span className="home-text124">
            TRC Tunisie Residence and Catering
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text125">
            Découvrez notre résidence confortable et nos repas adaptés, réservez votre place dès maintenant.
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
              <Link to="/Reserver" className="navbar8-text16">Réserver Maintenant</Link>
          </Fragment>
        }
      ></CTA26>
      <Features25
        feature3Description={
          <Fragment>
            <span className="home-text127">
              Système de sécurité 24h/24 pour assurer la tranquillité
              d&apos;esprit des résidents.
            </span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text128">
              Des chambres entièrement meublées et équipées pour assurer un
              séjour confortable.
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text129">Emplacement idéal</span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="home-text130">Chambres confortables</span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text131">
              Situation centrale proche des universités, des transports en
              commun et des commodités.
            </span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text132">Sécurité renforcée</span>
          </Fragment>
        }
      ></Features25>
      <Pricing14
        plan3Price={
          <Fragment>
            <span className="home-text133">$49/mo</span>
          </Fragment>
        }
        plan3Action={
          <Fragment>
            <span className="home-text134">Get started</span>
          </Fragment>
        }
        plan11={
          <Fragment>
            <span className="home-text135">Basic plan</span>
          </Fragment>
        }
        plan1Action={
          <Fragment>
            <span className="home-text136">Get started</span>
          </Fragment>
        }
        plan31={
          <Fragment>
            <span className="home-text137">Enterprise plan</span>
          </Fragment>
        }
        plan3Feature41={
          <Fragment>
            <span className="home-text138">Feature text goes here</span>
          </Fragment>
        }
        plan1Feature2={
          <Fragment>
            <span className="home-text139">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature11={
          <Fragment>
            <span className="home-text140">Feature text goes here</span>
          </Fragment>
        }
        plan3Feature51={
          <Fragment>
            <span className="home-text141">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature41={
          <Fragment>
            <span className="home-text142">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature2={
          <Fragment>
            <span className="home-text143">Feature text goes here</span>
          </Fragment>
        }
        plan3Feature21={
          <Fragment>
            <span className="home-text144">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature4={
          <Fragment>
            <span className="home-text145">Feature text goes here</span>
          </Fragment>
        }
        plan2Yearly={
          <Fragment>
            <span className="home-text146">or $299 yearly</span>
          </Fragment>
        }
        plan1Action1={
          <Fragment>
            <span className="home-text147">Get started</span>
          </Fragment>
        }
        plan2Action={
          <Fragment>
            <span className="home-text148">Get started</span>
          </Fragment>
        }
        plan3Feature1={
          <Fragment>
            <span className="home-text149">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature3={
          <Fragment>
            <span className="home-text150">Feature text goes here</span>
          </Fragment>
        }
        plan1Price1={
          <Fragment>
            <span className="home-text151">$200/yr</span>
          </Fragment>
        }
        plan2={
          <Fragment>
            <span className="home-text152">Business plan</span>
          </Fragment>
        }
        plan2Feature21={
          <Fragment>
            <span className="home-text153">Feature text goes here</span>
          </Fragment>
        }
        plan2Action1={
          <Fragment>
            <span className="home-text154">Get started</span>
          </Fragment>
        }
        plan3Feature2={
          <Fragment>
            <span className="home-text155">Feature text goes here</span>
          </Fragment>
        }
        content1={
          <Fragment>
            
          </Fragment>
        }
        plan2Feature1={
          <Fragment>
            <span className="home-text157">Feature text goes here</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text158">Pricing plan</span>
          </Fragment>
        }
        plan3Feature31={
          <Fragment>
            <span className="home-text159">Feature text goes here</span>
          </Fragment>
        }
        plan1={
          <Fragment>
            <span className="home-text160">Basic plan</span>
          </Fragment>
        }
        plan21={
          <Fragment>
            <span className="home-text161">Business plan</span>
          </Fragment>
        }
        plan1Feature11={
          <Fragment>
            <span className="home-text162">Feature text goes here</span>
          </Fragment>
        }
        plan1Feature21={
          <Fragment>
            <span className="home-text163">Feature text goes here</span>
          </Fragment>
        }
        plan3Feature5={
          <Fragment>
            <span className="home-text164">Feature text goes here</span>
          </Fragment>
        }
        plan2Yearly1={
          <Fragment>
            <span className="home-text165">or $29 monthly</span>
          </Fragment>
        }
        plan2Price={
          <Fragment>
            <span className="home-text166">$29/mo</span>
          </Fragment>
        }
        plan3Yearly1={
          <Fragment>
            <span className="home-text167">or $49 monthly</span>
          </Fragment>
        }
        plan2Feature31={
          <Fragment>
            <span className="home-text168">Feature text goes here</span>
          </Fragment>
        }
        plan3Feature11={
          <Fragment>
            <span className="home-text169">Feature text goes here</span>
          </Fragment>
        }
        plan1Yearly1={
          <Fragment>
            <span className="home-text170">or $20 monthly</span>
          </Fragment>
        }
        plan2Price1={
          <Fragment>
            <span className="home-text171">$299/yr</span>
          </Fragment>
        }
        plan3Yearly={
          <Fragment>
            <span className="home-text172">or $499 yearly</span>
          </Fragment>
        }
        plan3Feature4={
          <Fragment>
            <span className="home-text173">Feature text goes here</span>
          </Fragment>
        }
        plan3Price1={
          <Fragment>
            <span className="home-text174">$499/yr</span>
          </Fragment>
        }
        plan1Feature31={
          <Fragment>
            <span className="home-text175">Feature text goes here</span>
          </Fragment>
        }
        plan1Feature3={
          <Fragment>
            <span className="home-text176">Feature text goes here</span>
          </Fragment>
        }
        plan1Yearly={
          <Fragment>
            <span className="home-text177">or $200 yearly</span>
          </Fragment>
        }
        plan1Feature1={
          <Fragment>
            <span className="home-text178">Feature text goes here</span>
          </Fragment>
        }
        plan3Feature3={
          <Fragment>
            <span className="home-text179">Feature text goes here</span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="home-text180">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        plan3Action1={
          <Fragment>
            <span className="home-text183">Get started</span>
          </Fragment>
        }
        plan1Price={
          <Fragment>
            <span className="home-text184">$20/mo</span>
          </Fragment>
        }
        plan3={
          <Fragment>
            <span className="home-text185">Enterprise plan</span>
          </Fragment>
        }
      ></Pricing14>
      <Steps2
        step1Description={
          <Fragment>
            <span className="home-text186">
              Parcourez notre sélection de chambres confortables et choisissez
              celle qui vous convient le mieux.
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text187">
              Une fois votre réservation confirmée, arrivez à TRC et
              installez-vous dans votre nouvelle chambre.
            </span>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <span className="home-text188">Effectuez votre réservation</span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text189">
              Remplissez le formulaire de réservation en ligne pour réserver
              votre chambre en quelques clics.
            </span>
          </Fragment>
        }
        step1Title={
          <Fragment>
            <span className="home-text190">Choisissez votre chambre</span>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <span className="home-text191">Arrivez et installez-vous</span>
          </Fragment>
        }
        step4Description={
          <Fragment>
            <span className="home-text192">
            Bénéficiez de toutes les commodités et services de qualité offerts par TRC Tunisie pour un séjour agréable et sans souci.
            </span>
          </Fragment>
        }
        step4Title={
          <Fragment>
            <span className="home-text193">Profitez de votre séjour</span>
          </Fragment>
        }
      ></Steps2>
     
      <Contact10
        content1={
          <Fragment>Image de l'extérieur du bâtiment
            <span className="home-text208">
              Vous pouvez nous contacter pour toute question ou réservation.
            </span>
          </Fragment>
        }
        location1Description={
          <Fragment>
            <span className="home-text209">
           HQ:  KM 7, Route Mateur, Manouba 2010
           <br></br>
           Tataouine : Route Remada KM 7, Tataouine, Tunisia
          
            
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text210">A Propos </span>
          </Fragment>
        }
        location2Description={
          <Fragment>
            <span className="home-text211">
              Nous sommes situés à proximité  des traveuax
            </span>
          </Fragment>
        }
        location1={
          <Fragment>
            <span className="home-text212">Réactivez votre énergie ici.</span>
          </Fragment>
        }
        location2={
          <Fragment>
            <span className="home-text213">Emplacement</span>
          </Fragment>
        }
      ></Contact10>
      <Footer4
      
      ></Footer4>
    </div>
  )
}

export default Home
