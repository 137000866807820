import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Navbar8 from '../components/navbar8';
import Footer4 from '../components/footer4';
import './Contacter.css';

const Contacter = () => {
  return (
    <div className="contact-container">
      <Navbar8 
        page4Description={<span className="home-text100">Contactez-nous pour toute </span>}
        action1={<span className="home-text101">Contacter maintenant</span>}
      
       
        link1={<Fragment></Fragment>}
        page4={<span className="home-text105">Contact</span>}
        
      />

      <div className="contact-content">
        <div className="text-center">
          <h1 className="contact-title">Contact</h1>
          <p className="contact-description">
            Si vous avez des questions, n'hésitez pas à nous contacter !
          </p>
        </div>

        <div className="form-container">
          <form className="contact-form">
            <div className="form-group">
              <label htmlFor="name" className="form-label">Nom</label>
              <input type="text" id="name" name="name" className="form-input" required />
            </div>

            <div className="form-group">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" id="email" name="email" className="form-input" required />
            </div>

            <div className="form-group">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea id="message" name="message" rows="4" className="form-input" required></textarea>
            </div>

            <div className="form-action">
              <button type="submit" className="submit-button">Envoyer le message</button>
            </div>
          </form>
        </div>
      </div>

      <Footer4 />
    </div>
  );
};

export default Contacter;
