import React, { useState } from 'react';
import Navbar8 from '../components/navbar8';
import Footer4 from '../components/footer4';
import { db, addDoc, collection } from './Firebase'; // Import Firebase methods
import './Reserver.css';

const Reserve = () => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    num_telephone: '',
    date_deb: '',
    date_fin: '',
    chambre_type: 'single', // Default value for room type
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add data to Firestore in the "reservations" collection
      await addDoc(collection(db, "reservations"), formData);
      console.log('Form Submitted and Data Saved:', formData);
      alert('Réservation réussie et enregistrée dans la base de données!');
      
      // Reset the form after submission
      setFormData({
        nom: '',
        prenom: '',
        num_telephone: '',
        date_deb: '',
        date_fin: '',
        chambre_type: 'single',
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      alert('Erreur lors de l\'enregistrement de la réservation.');
    }
  };

  return (
    <div className="reserve-page">
      <Navbar8 
        page4Description={<span className="home-text100">Réservez votre chambre facilement en ligne.</span>}
        action1={<span className="home-text101">Réserver Maintenant</span>}
        
        
        link1={<></>}
        page4={<span className="home-text105">Réservation</span>}
       
      />

      <section className="reserve-section">
        <div className="reserve-container">
          <div className="reserve-header">
            <h2 className="reserve-title">Réservez une Chambre</h2>
            <p className="reserve-description">
              Veuillez remplir les informations ci-dessous pour réserver votre chambre.
            </p>
          </div>

          <form onSubmit={handleSubmit} className="reserve-form">
            <div className="reserve-row">
              <div className="reserve-field">
                <label htmlFor="nom" className="reserve-label">Nom</label>
                <input
                  type="text"
                  id="nom"
                  name="nom"
                  value={formData.nom}
                  onChange={handleChange}
                  required
                  className="reserve-input"
                />
              </div>

              <div className="reserve-field">
                <label htmlFor="prenom" className="reserve-label">Prénom</label>
                <input
                  type="text"
                  id="prenom"
                  name="prenom"
                  value={formData.prenom}
                  onChange={handleChange}
                  required
                  className="reserve-input"
                />
              </div>
            </div>

            <div className="reserve-field">
              <label htmlFor="num_telephone" className="reserve-label">Numéro de Téléphone</label>
              <input
                type="tel"
                id="num_telephone"
                name="num_telephone"
                value={formData.num_telephone}
                onChange={handleChange}
                required
                className="reserve-input"
              />
            </div>

            <div className="reserve-row">
              <div className="reserve-field">
                <label htmlFor="date_deb" className="reserve-label">Date de Début</label>
                <input
                  type="date"
                  id="date_deb"
                  name="date_deb"
                  value={formData.date_deb}
                  onChange={handleChange}
                  required
                  className="reserve-input"
                />
              </div>

              <div className="reserve-field">
                <label htmlFor="date_fin" className="reserve-label">Date de Fin</label>
                <input
                  type="date"
                  id="date_fin"
                  name="date_fin"
                  value={formData.date_fin}
                  onChange={handleChange}
                  required
                  className="reserve-input"
                />
              </div>
            </div>

            {/* Chambre Type (Room Type) Section */}
            <div className="reserve-field">
              <label htmlFor="chambre_type" className="reserve-label">Type de Chambre</label>
              <select
                id="chambre_type"
                name="chambre_type"
                value={formData.chambre_type}
                onChange={handleChange}
                required
                className="reserve-input"
              >
                <option value="single"> Chambre Single </option>
                <option value="single_suite">Chambre Suite Single</option>
                <option value="double">Chambre Double</option>
                <option value="double_suite">Chambre Double Suite</option>
                <option value="triple">Chambre Triple</option>
                <option value="triple_suite">Chambre Triple Suite </option>
                <option value="quadruple">Chambre Quadruple</option>
                <option value="quadruple_suite">Chambre Quadruple Suite</option>
              </select>
            </div>

            <button type="submit" className="reserve-button">
              Réserver Maintenant
            </button>
          </form>
        </div>
      </section>

      <Footer4 />
    </div>
  );
};

export default Reserve;
